<!--

      This view displays the details of one recent order. It is reachable
      from within the profile tab by clicking on recent orders and then
      selecting one specific order.

 -->

<template>
  <Page :title='title' :hidefooter="true">
    <TabSwiper :tabs="tabs" :tab="tab" @clicked="t => tab = t" />
    <div class="info-tiles" v-if="days && order" v-show="tab === 'Rezepte'">
      <div class="info-tile-4">
        <div class="btn-link">{{ days.length }}</div>
        Tage
      </div>
      <div class="info-tile-4">
        <div class="btn-link">
          {{ people }}
        </div>
        Personen
      </div>
      <div class="info-tile-4">
        <div class="btn-link">
          <Price :price="pricePerServing" />
        </div>
        / Portion
      </div>
      <div class="info-tile-4" @click="openLeftovers()">
        <div class="btn-link">{{ leftoversConsumption }}%</div>
        <div class="tertiary-text">Verbrauch</div>
      </div>
    </div>
    <RecipeCard
      v-show="tab === 'Rezepte'"
      v-for="(day) in this.days"
      :key="day.id"
      :rplan="day.planrecipes[0]"
      ref="recipecard"
      :showAvailability="true"
    />
    <ion-skeleton-text animated class="skeleton-card" v-if="showSkeletonCards && tab === 'Rezepte'"></ion-skeleton-text>
    <ion-skeleton-text animated class="skeleton-card" v-if="showSkeletonCards && tab === 'Rezepte'"></ion-skeleton-text>
    <ion-skeleton-text animated class="skeleton-card" v-if="showSkeletonCards && tab === 'Rezepte'"></ion-skeleton-text>
    <PartialList
      v-show="tab === 'Produkte'"
      v-for="cat in categories"
      v-bind:key="cat.id"
      :items="cat"
      :ordered="true"
    />
    <div class="white-box-small main-text total-bar" v-if="days" v-show="tab === 'Produkte'">
      <div>Gesamt</div>
      <Price :price="order.price" v-if="order && order.price" />
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Price from '@/components/Price.vue'
import TabSwiper from '@/components/TabSwiper.vue'
import RecipeCard from '@/components/cards/RecipeCard.vue'
import PartialList from '@/components/shopping/PartialList.vue'
import { IonSkeletonText } from '@ionic/vue'

export default {
  name: 'order',
  components: {
    Page,
    Price,
    TabSwiper,
    RecipeCard,
    PartialList,
    IonSkeletonText
  },
  data () {
    return {
      tabs: [
        'Rezepte',
        'Produkte'
      ],
      tab: 'Rezepte',
      days: null,
      plan: null,
      showSkeletonCards: null,
      order: null,
      loadingLeftovers: null,
      leftovers: null
    }
  },
  computed: {
    people () {
      if (this.countServings && this.days.length) {
        return Math.round(this.countServings * 10 / this.days.length) / 10
      } else {
        return 0
      }
    },

    pricePerServing () {
      if (this.$store.state.neworder && this.$store.state.neworder.price && this.countServings) {
        return this.$store.state.neworder.price / this.countServings
      } else {
        return 0
      }
    },
    date () {
      if (this.$store.state.order && this.$store.state.order.ordered_at) {
        const d = new Date(this.$store.state.order.ordered_at)
        const today = new Date()
        let date = ''
        if (today - d > 1000*60*60*24*365) {
          date = d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear()
        } else {
          date = d.getDate() + '.' + (d.getMonth() + 1) + '.'
        }
        const time = d.getHours() + ':' + (d.getMinutes()<10?'0':'') + d.getMinutes()
        return [date, time]
      }
    },
    title () {
      if (this.date) {
        return "Bestellt am " + this.date[0]
      }
    },
    categories () {
      if (this.order) {
        let categories = []
        this.order.orderproducts.forEach(op => {
          if (op.quantity > 0) {
            const cat = op.product_category
            if (!categories.find(c => c.name === cat)) {
              categories.push({
                name: cat,
                products: [op]
              })
            } else {
              categories.find(c => c.name === cat).products.push(op)
            }
          }
        })
        return categories.sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    countServings () {
      if (this.plan && this.order) {
        let countServings = 0
        this.plan.single_days.forEach(day => {
          countServings += day.planrecipes[0].serving_size
        })
        return countServings
      }
    },
    leftoversConsumption () {
      if (this.leftovers && this.leftovers.hasOwnProperty('consumption_ratio')) {
        const consumption = Math.round(this.leftovers.consumption_ratio * 100)
        if (consumption > 100) {
          return 100
        } else {
          return consumption
        }
      }
    }
  },
  methods: {
    openLeftovers () {
      if (this.leftovers) {
        this.$store.dispatch('openModal', { name: 'leftovers', data: this.leftovers })
      }
    },
    getLeftovers () {
      const details = { url: this.$store.state.api.shop.order + this.order.id + '/leftovers/' }
      this.$store.dispatch('apiSend', details).then(result => {
        if (result) this.leftovers = result
      })
    },
    downloadDetailedOrder () {
      if (this.$store.state.order && this.$store.state.order.plan_id && !this.$store.state.order.orderproducts) {
        this.showSkeletonCards = true

        // Download the plan, i.e. the menu of recipes
        this.$store.dispatch('getPlanById', this.$store.state.order.plan_id).then(plan => {
          if (plan && plan.single_days) {
            this.plan = plan
            this.days = plan.single_days
            const recipeIds = this.days.map(day => day.planrecipes).map(planRecipe => planRecipe[0].recipe.id)
            this.$store.dispatch('getRecipesAvailability', recipeIds)
            this.showSkeletonCards = false
          }
        })

        // Download the order details
        const details = {
          url: this.$store.state.api.shop.order,
          id: this.$store.state.order.id
        }
        this.$store.dispatch('apiSend', details).then( order => {
          if (order) {
            this.order = order
            this.getLeftovers()
          }
        })
      }
    }
  },
  updated () {
    this.downloadDetailedOrder()
  }
}
</script>
